import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7a94cb6e"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = { class: "m-hotel-search-list-item-info" }
const _hoisted_3 = { class: "m-hotel-search-list-content" }
const _hoisted_4 = { class: "m-hotel-search-list-content-header" }
const _hoisted_5 = { class: "review" }
const _hoisted_6 = { class: "m-hotel-search-list-action-info" }
const _hoisted_7 = { class: "m-hotel-search-list-action" }
const _hoisted_8 = { class: "m-hotel-search-list-action-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MTypography = _resolveComponent("MTypography")!
  const _component_MIcon = _resolveComponent("MIcon")!
  const _component_MChip = _resolveComponent("MChip")!
  const _component_MButton = _resolveComponent("MButton")!
  const _component_MCard = _resolveComponent("MCard")!

  return (_openBlock(), _createBlock(_component_MCard, { class: "m-hotel-search-list-item" }, {
    default: _withCtx(() => [
      _createElementVNode("img", {
        src: _ctx.hotelThumbnail,
        onError: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onHotelThumbnailErrorHandler && _ctx.onHotelThumbnailErrorHandler(...args))),
        loading: "lazy",
        class: "m-hotel-search-list-thumbnail"
      }, null, 40, _hoisted_1),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", null, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_MTypography, {
                class: "property-name m-textoverflow",
                type: "title"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.title), 1)
                ]),
                _: 1
              }),
              _createElementVNode("div", _hoisted_5, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.hotelRating, (index) => {
                  return (_openBlock(), _createBlock(_component_MIcon, {
                    key: index,
                    class: "review-icon",
                    name: "m-star",
                    width: "20",
                    height: "20"
                  }))
                }), 128))
              ])
            ]),
            _withDirectives(_createVNode(_component_MTypography, {
              class: "property-address",
              type: "body"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_MIcon, {
                  class: "location-icon",
                  name: "m-location",
                  width: "20",
                  height: "20"
                }),
                _createVNode(_component_MTypography, {
                  type: "body",
                  class: "content"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.address), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 512), [
              [_vShow, _ctx.address]
            ])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _withDirectives(_createVNode(_component_MChip, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.supplierName), 1)
              ]),
              _: 1
            }, 512), [
              [_vShow, _ctx.supplierName]
            ]),
            _withDirectives(_createVNode(_component_MChip, { variant: _ctx.refundTypeVariant }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.refundType), 1)
              ]),
              _: 1
            }, 8, ["variant"]), [
              [_vShow, _ctx.refundType]
            ]),
            _withDirectives(_createVNode(_component_MChip, { variant: "success" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.prepaymentText), 1)
              ]),
              _: 1
            }, 512), [
              [_vShow, _ctx.prepaymentText]
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _withDirectives(_createVNode(_component_MTypography, { type: "label" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.startingFromText), 1)
              ]),
              _: 1
            }, 512), [
              [_vShow, _ctx.startingFromText]
            ]),
            _withDirectives(_createVNode(_component_MTypography, { type: "title" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.price), 1)
              ]),
              _: 1
            }, 512), [
              [_vShow, _ctx.price]
            ]),
            _withDirectives(_createVNode(_component_MTypography, { type: "body" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.daysInfo), 1)
              ]),
              _: 1
            }, 512), [
              [_vShow, _ctx.daysInfo]
            ]),
            _withDirectives(_createVNode(_component_MTypography, { type: "label" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.travelerInfo), 1)
              ]),
              _: 1
            }, 512), [
              [_vShow, _ctx.travelerInfo]
            ])
          ]),
          _createVNode(_component_MButton, {
            class: "m-hotel-search-list-action-button",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('on:click')))
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Show Details ")
            ]),
            _: 1
          })
        ])
      ])
    ]),
    _: 1
  }))
}